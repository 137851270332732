var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                { staticClass: "pt-0 pb-10", attrs: { cols: "12" } },
                [
                  _c("AsideBenefitInput", {
                    ref: "aside_benefit_input",
                    attrs: {
                      formType: _vm.formType,
                      previewMaxHeight: "118px",
                      previewMaxWidth: "240px"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "7" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.BenefitForm.name.label"),
                          placeholder: _vm.$t(
                            "forms.BenefitForm.name.placeholder"
                          ),
                          type: "text",
                          "error-messages": _vm.getErrorMessages("name")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("name")
                          }
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "type_field",
                        attrs: {
                          label: _vm.$t("forms.BenefitForm.type.label"),
                          placeholder: _vm.$t(
                            "forms.BenefitForm.type.placeholder"
                          ),
                          hint: _vm.$t("forms.BenefitForm.type.hint"),
                          type: "text",
                          readonly: "",
                          "error-messages": _vm.getErrorMessages("type")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("type")
                          }
                        },
                        model: {
                          value: _vm.value.type,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "type", $$v)
                          },
                          expression: "value.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-textarea", {
                        ref: "description_field",
                        attrs: {
                          label: _vm.$t("forms.BenefitForm.description.label"),
                          placeholder: _vm.$t(
                            "forms.BenefitForm.description.placeholder"
                          ),
                          type: "text",
                          rows: "4",
                          "error-messages": _vm.getErrorMessages("description")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("description")
                          }
                        },
                        model: {
                          value: _vm.value.description,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "description", $$v)
                          },
                          expression: "value.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-8 pb-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "mr-0 primary--text",
                              attrs: { for: "tos_url_field" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("forms.BenefitForm.tos_url.label")
                                )
                              )
                            ]
                          ),
                          _c("FileInput", {
                            ref: "tos_url_field",
                            staticClass: "mt-2",
                            attrs: {
                              value: _vm.value.tos_url,
                              postUpload: _vm.updateBenefitField("tos_url"),
                              type: "update-benefit-tos",
                              params: {
                                level_id: _vm.value.level_id,
                                benefit_id: _vm.value.id
                              },
                              maxSizeMB: 4,
                              manual: _vm.formType == "CREATE",
                              "error-messages": _vm.getErrorMessages("tos_url")
                            },
                            on: {
                              input: function($event) {
                                _vm.onInput("tos_url", $event)
                                _vm.clearErrorMessages("tos_url")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "px-0 pt-6",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "5" }
                },
                [
                  _c("AsideBenefitInput", {
                    ref: "aside_benefit_input",
                    attrs: {
                      formType: _vm.formType,
                      previewMaxHeight: "200px",
                      previewMaxWidth: "200px"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }