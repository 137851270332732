<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <div v-if="isDetails">
                <v-img v-if="value.icon_url" :src="value.icon_url" :max-height="$vuetify.breakpoint.mdAndUp ? previewMaxHeight : ''" :max-width="previewMaxWidth" class="mb-4 mx-auto pointer image-preview" contain></v-img>
            </div>
            <div v-else>
                <ImageInput
                    ref="icon_field"
                    :previewMaxHeight="previewMaxHeight"
                    :previewMaxWidth="previewMaxWidth"
                    v-model="value.icon_url"
                    :postUpload="updateBenefitField('icon_url')"
                    type="update-benefit-icon"
                    :params="{ level_id: value.level_id, benefit_id: value.id }"
                    required
                    :manual="formType == 'CREATE'"
                    :error-messages="getErrorMessages('icon_url')"
                    @input="clearErrorMessages('icon_url')"
                    class="mb-4 mx-auto"
                />
            </div>
                
            <ColorPickerInput
                ref="color_field"
                mode="hexa"
                :hide-canvas="isDetails"
                :disabled="isDetails"
                :width="previewMaxWidth"
                :label="$t('forms.BenefitForm.color.label')"
                v-model="value.color"
                :error-messages="getErrorMessages('color')"
                @input="colorChanged($event); clearErrorMessages('color')"
                class="mx-auto elevation-0"
            />
        </v-col>
    </v-row>
</template>

<script>
import ColorPickerInput from '@/components/inputs/ColorPickerInput'
import ImageInput from '@/components/inputs/ImageInput'
import FormMixin from '@/mixins/FormMixin'
import Benefit from '@/models/Benefit'

export default {
    name: 'AsideBenefitInput',
    mixins: [ FormMixin(Benefit, '') ],
    components: { ImageInput, ColorPickerInput },
    props: {
        formType: {
            type: String,
            required: true,
        },
        previewMaxHeight: {
            type: String,
            default: '270px'
        },
        previewMaxWidth: {
            type: String,
            default: '200px',
        },
    },
    methods: {
        async triggerFileUploads() {
            await this.$refs.icon_field.requestFileUpload()
        },
        colorChanged(value) {
            this.value.color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        updateBenefitField(attributeName) {
            return async (value) => {
                this.value[attributeName] = value
            }
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>

<style scoped lang="scss">
.image-preview {
    border-radius: 8px !important;
}
</style>