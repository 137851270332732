var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "570px"
      },
      on: { cancel: _vm.cancel, confirm: _vm.confirm },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                { staticClass: "pt-0 pb-10", attrs: { cols: "12" } },
                [
                  _c("AsideBenefitInput", {
                    ref: "aside_benefit_input",
                    attrs: {
                      formType: "DETAILS",
                      previewMaxHeight: "118px",
                      previewMaxWidth: "240px"
                    },
                    model: {
                      value: _vm.benefit,
                      callback: function($$v) {
                        _vm.benefit = $$v
                      },
                      expression: "benefit"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "7" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.BenefitForm.name.label"),
                          readonly: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.benefit.name,
                          callback: function($$v) {
                            _vm.$set(_vm.benefit, "name", $$v)
                          },
                          expression: "benefit.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "type_field",
                        attrs: {
                          label: _vm.$t("forms.BenefitForm.type.label"),
                          readonly: "",
                          disabled: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.benefit.type,
                          callback: function($$v) {
                            _vm.$set(_vm.benefit, "type", $$v)
                          },
                          expression: "benefit.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-textarea", {
                        ref: "description_field",
                        attrs: {
                          label: _vm.$t("forms.BenefitForm.description.label"),
                          readonly: "",
                          type: "text",
                          rows: "4"
                        },
                        model: {
                          value: _vm.benefit.description,
                          callback: function($$v) {
                            _vm.$set(_vm.benefit, "description", $$v)
                          },
                          expression: "benefit.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.benefit.tos_url
                    ? _c(
                        "v-col",
                        { staticClass: "pt-8 pb-4", attrs: { cols: "12" } },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "label",
                              {
                                staticClass: "mr-0 primary--text",
                                attrs: { for: "tos_url_field" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "forms.BenefitForm.tos_url.label_view"
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-chip",
                                  {
                                    ref: "tos_url_field",
                                    staticClass:
                                      "mt-2 secondary_font--text background",
                                    attrs: { small: "" },
                                    on: { click: _vm.redirectToFile }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.inputs.FileInput.view"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-6 primary--text",
                                        attrs: { "x-small": "" }
                                      },
                                      [_vm._v("fas fa-fw fa-file-download")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "px-0 pt-6",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "5" }
                },
                [
                  _c("AsideBenefitInput", {
                    ref: "aside_benefit_input",
                    attrs: {
                      formType: "DETAILS",
                      previewMaxHeight: "200px",
                      previewMaxWidth: "200px"
                    },
                    model: {
                      value: _vm.benefit,
                      callback: function($$v) {
                        _vm.benefit = $$v
                      },
                      expression: "benefit"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }