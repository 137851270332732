<template>
    <v-container fluid class="pt-0">
        <CreateModal ref="create_modal" :benefit="selectedBenefit" />
        <DetailsModal ref="details_modal" :benefit="selectedBenefit" />
        <EditModal ref="edit_modal" :benefit="selectedBenefit" />

        <span class="pl-5 pl-md-12 section-title">
            <span v-if="level">{{ level.name }}:</span>
            <v-progress-circular v-else indeterminate/>
            {{ $t('views.levels.benefits.List.title') }}
        </span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <BenefitsTable 
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10" 
            :fetchFunction="fetchFunction"
            @create="openCreate" 
            @details="openDetails" 
            @edit="openEdit"
            @delete="handleDelete"
        />
    </v-container>
</template>

<script>
import DetailsModal         from '@/components/modals/benefits/DetailsModal'
import CreateModal          from '@/components/modals/benefits/CreateModal'
import EditModal            from '@/components/modals/benefits/EditModal'
import BenefitsTable        from '@/components/tables/BenefitsTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Benefit              from '@/models/Benefit'
import Level                from '@/models/Level'

export default {
    name: 'LevelBenefitsList',
    props: {
        levelId: {
            type: String,
            required: true,
        },
    },
    mixins: [ HasErrorHandlerMixin ],
    components: { BenefitsTable, CreateModal, DetailsModal, EditModal },
    data: () => ({
        level          : null,
        selectedBenefit: new Benefit(),
        hasError       : false,
    }),
    methods: {
        async openCreate() {
            this.selectedBenefit = new Benefit({ level_id: this.levelId })
            let r = await this.$refs.create_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.levels.benefits.List.alerts.create_success'), 'success')
            await this.refreshTable()
        },
        async openDetails(benefit) {
            this.selectedBenefit = benefit
            this.$refs.details_modal.open()
        },
        async openEdit(benefit) {
            // Precisa criar uma nova instância
            this.selectedBenefit = new Benefit(this.$lodash.cloneDeep(benefit))
            let r = await this.$refs.edit_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.levels.benefits.List.alerts.edit_success'), 'success')
            await this.refreshTable()
        },
        async handleDelete(benefit) {
            await benefit.delete()
                .catch(this.preErrorHandler)

            if (this.hasError) {
                this.hasError = false
                return
            }

            this.$bus.$emit('message', this.$t('views.levels.benefits.List.alerts.delete_success'), 'success')
            await this.refreshTable()    
        },
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            
            // Busca o nível a que pertencem os prêmios
            let level    = new Level({ id: this.levelId })
            let response = await level.get()
                .catch(this.preErrorHandler)

            if (this.hasError)
                return []

            this.level = new Level(response.data)

            // Busca os benefícios do nível
            response = await Benefit.list(this.levelId, pagination)
                .catch(this.preErrorHandler)

            return Benefit.hydrate(this.$lodash.get(response, 'data', []))
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
}
</script>