var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c("CreateModal", {
        ref: "create_modal",
        attrs: { benefit: _vm.selectedBenefit }
      }),
      _c("DetailsModal", {
        ref: "details_modal",
        attrs: { benefit: _vm.selectedBenefit }
      }),
      _c("EditModal", {
        ref: "edit_modal",
        attrs: { benefit: _vm.selectedBenefit }
      }),
      _c(
        "span",
        { staticClass: "pl-5 pl-md-12 section-title" },
        [
          _vm.level
            ? _c("span", [_vm._v(_vm._s(_vm.level.name) + ":")])
            : _c("v-progress-circular", { attrs: { indeterminate: "" } }),
          _vm._v(" " + _vm._s(_vm.$t("views.levels.benefits.List.title")) + " ")
        ],
        1
      ),
      _vm.hasError
        ? _c(
            "v-alert",
            {
              staticClass: "mt-4 mx-5 mt-md-6 mx-md-12",
              attrs: { type: "error" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("errors.generic")) + " ")]
          )
        : _c("BenefitsTable", {
            ref: "table",
            staticClass: "my-4 mx-2 mx-md-10",
            attrs: { fetchFunction: _vm.fetchFunction },
            on: {
              create: _vm.openCreate,
              details: _vm.openDetails,
              edit: _vm.openEdit,
              delete: _vm.handleDelete
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }