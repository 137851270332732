<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pt-0 pb-10">
                <AsideBenefitInput
                    ref="aside_benefit_input"
                    v-model="value"
                    :formType="formType"
                    previewMaxHeight="118px"
                    previewMaxWidth="240px"
                />
            </v-col>
            
            <v-col cols="12" xs="12" sm="12" md="7" class="py-0">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            :ref="`name_field`"
                            :label="$t('forms.BenefitForm.name.label')"
                            :placeholder="$t('forms.BenefitForm.name.placeholder')"
                            type="text"
                            v-model="value.name"
                            :error-messages="getErrorMessages('name')"
                            @input="clearErrorMessages('name')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            :ref="`type_field`"
                            :label="$t('forms.BenefitForm.type.label')"
                            :placeholder="$t('forms.BenefitForm.type.placeholder')"
                            :hint="$t('forms.BenefitForm.type.hint')"
                            type="text"
                            v-model="value.type"
                            readonly
                            :error-messages="getErrorMessages('type')"
                            @input="clearErrorMessages('type')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-textarea
                            :ref="`description_field`"
                            :label="$t('forms.BenefitForm.description.label')"
                            :placeholder="$t('forms.BenefitForm.description.placeholder')"
                            type="text"
                            v-model="value.description"
                            rows="4"
                            :error-messages="getErrorMessages('description')"
                            @input="clearErrorMessages('description')"
                        />
                    </v-col>

                    <v-col cols="12" class="pt-8 pb-4">
                        <div class="d-flex flex-column">
                            <label for="tos_url_field" class="mr-0 primary--text">{{ $t('forms.BenefitForm.tos_url.label') }}</label>
                            <FileInput 
                                ref="tos_url_field"
                                :value="value.tos_url"
                                :postUpload="updateBenefitField('tos_url')"
                                type="update-benefit-tos"
                                :params="{ level_id: value.level_id, benefit_id: value.id }"
                                :maxSizeMB="4"
                                :manual="formType == 'CREATE'"
                                :error-messages="getErrorMessages('tos_url')"
                                @input="onInput('tos_url', $event); clearErrorMessages('tos_url')"
                                class="mt-2"
                            />
                        </div>
                    </v-col>   
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" xs="12" sm="12" md="5" class="px-0 pt-6">
                <AsideBenefitInput
                    ref="aside_benefit_input"
                    v-model="value"
                    :formType="formType"
                    previewMaxHeight="200px"
                    previewMaxWidth="200px"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import FileInput         from '@/components/inputs/FileInput'
import AsideBenefitInput from './commons/AsideBenefitInput'
import FormMixin         from '@/mixins/FormMixin'
import Benefit           from '@/models/Benefit'

export default {
    name: 'BenefitForm',
    props: {
        formType: String,
    },
    mixins: [ FormMixin(Benefit, 'name_field') ],
    components: { FileInput, AsideBenefitInput },
    methods: {
        colorChanged(value) {
            this.value.color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        onInput(attributeName, value) {
            this.$set(this.value, attributeName, value)
        },

        presubmit() {
            // Salva o preview da foto
            this.iconPreview = this.value.icon_url
            this.value.icon_url = null
            return true
        },
        postsubmit() {
            // Recupera o preview da foto
            this.value.icon_url = this.iconPreview
            this.iconPreview = null
            return true
        },

        async triggerFileUploads() {
            await this.$refs.aside_benefit_input.triggerFileUploads()
            await this.$refs.tos_url_field.requestFileUpload()
        },

        updateBenefitField(attributeName) {
            return async (value) => {
                this.value[attributeName] = value
            }
        },
    },
}
</script>