<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
    >
        <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pt-0 pb-10">
                <AsideBenefitInput
                    ref="aside_benefit_input"
                    v-model="benefit"
                    formType="DETAILS"
                    previewMaxHeight="118px"
                    previewMaxWidth="240px"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="7" class="py-0">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            :ref="`name_field`"
                            :label="$t('forms.BenefitForm.name.label')"
                            readonly type="text"
                            v-model="benefit.name"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            :ref="`type_field`"
                            :label="$t('forms.BenefitForm.type.label')"
                            readonly disabled type="text"
                            v-model="benefit.type"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <cp-textarea
                            :ref="`description_field`"
                            :label="$t('forms.BenefitForm.description.label')"
                            readonly type="text"
                            v-model="benefit.description"
                            rows="4"
                        />
                    </v-col>
                    <v-col v-if="benefit.tos_url" cols="12" class="pt-8 pb-4">
                        <div class="d-flex flex-column">
                            <label for="tos_url_field" class="mr-0 primary--text">{{ $t('forms.BenefitForm.tos_url.label_view') }}</label>
                            <div>
                                <v-chip
                                    ref="tos_url_field"
                                    @click="redirectToFile"
                                    small class="mt-2 secondary_font--text background"
                                >
                                    {{ $t('components.inputs.FileInput.view') }}
                                    <v-icon x-small class="ml-6 primary--text">fas fa-fw fa-file-download</v-icon>
                                </v-chip>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" xs="12" sm="12" md="5" class="px-0 pt-6">
                <AsideBenefitInput
                    ref="aside_benefit_input"
                    v-model="benefit"
                    formType="DETAILS"
                    previewMaxHeight="200px"
                    previewMaxWidth="200px"
                />
            </v-col>
        </v-row>
    </BaseModal>
</template>

<script>
import AsideBenefitInput from '@/components/forms/commons/AsideBenefitInput'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Benefit from '@/models/Benefit'

export default {
    name: 'BenefitsDetailsModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, AsideBenefitInput },
    props: {
        benefit: {
            type: Benefit,
            required: true,
        },
    },
    data: vm => {
        return {
            title: vm.$t('views.levels.benefits.Details.title'),
        }
    },
    methods: {
        redirectToFile() {
            if (this.benefit)
                window.open(this.benefit.tos_url, '_blank')
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>