<template>
    <BaseCrudTable 
        ref="table" 
        tableName="BenefitsTable" 
        :headerConfigs="headerConfigs"
        :fetchFunction="fetchFunction"
        :pagination="pagination" 
        @create="handleCreate" 
        @details="handleDetails"
        @edit="handleEdit" 
        @delete="handleDelete"
    >
        <template #item.icon="{ item }">
            <v-img v-if="item.icon_url" :src="item.icon_url" height="30px" width="30px" contain></v-img>
            <v-icon v-else class="secondary_font--text">fas fa-fw fa-image</v-icon>
        </template>

        <template #item.color="{ item }">
            <ColorBox :color="item.color"></ColorBox>
        </template>
    </BaseCrudTable>
</template>

<script>
import BaseCrudTable from '@/components/tables/BaseCrudTable'
import ColorBox      from '@/components/boxes/ColorBox'
import Benefit       from '@/models/Benefit'

export default {
    name: 'BenefitsTable',
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    components: { BaseCrudTable, ColorBox },
    data: () => ({
        pagination: {
            page    : 1,
            per_page: 5,
        },
        headerConfigs: [
            {
                value: 'icon',
                showOnMobile: true,
            },
            {
                value: 'color',
                showOnMobile: true,
            },
            {
                value: 'name', 
                showOnMobile: false,
            },
            {
                value: 'description',
                showOnMobile: false,
            },
        ],
    }),
    created() {
        this.pagination.page = Number(this.$route.query.page) || this.pagination.page || 1
    },
    methods: {
        async refresh() {
            await this.$refs.table.preFetchItems()
        },
        handleCreate() {
            this.$emit('create')
        },
        handleDetails(item) {
            this.$emit('details', item)
        },
        handleEdit(item) {
            this.$emit('edit', item)
        },
        handleDelete(item) {
            this.$emit('delete', item)
        },
    },
}
</script>