var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _vm.isDetails
            ? _c(
                "div",
                [
                  _vm.value.icon_url
                    ? _c("v-img", {
                        staticClass: "mb-4 mx-auto pointer image-preview",
                        attrs: {
                          src: _vm.value.icon_url,
                          "max-height": _vm.$vuetify.breakpoint.mdAndUp
                            ? _vm.previewMaxHeight
                            : "",
                          "max-width": _vm.previewMaxWidth,
                          contain: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("ImageInput", {
                    ref: "icon_field",
                    staticClass: "mb-4 mx-auto",
                    attrs: {
                      previewMaxHeight: _vm.previewMaxHeight,
                      previewMaxWidth: _vm.previewMaxWidth,
                      postUpload: _vm.updateBenefitField("icon_url"),
                      type: "update-benefit-icon",
                      params: {
                        level_id: _vm.value.level_id,
                        benefit_id: _vm.value.id
                      },
                      required: "",
                      manual: _vm.formType == "CREATE",
                      "error-messages": _vm.getErrorMessages("icon_url")
                    },
                    on: {
                      input: function($event) {
                        return _vm.clearErrorMessages("icon_url")
                      }
                    },
                    model: {
                      value: _vm.value.icon_url,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "icon_url", $$v)
                      },
                      expression: "value.icon_url"
                    }
                  })
                ],
                1
              ),
          _c("ColorPickerInput", {
            ref: "color_field",
            staticClass: "mx-auto elevation-0",
            attrs: {
              mode: "hexa",
              "hide-canvas": _vm.isDetails,
              disabled: _vm.isDetails,
              width: _vm.previewMaxWidth,
              label: _vm.$t("forms.BenefitForm.color.label"),
              "error-messages": _vm.getErrorMessages("color")
            },
            on: {
              input: function($event) {
                _vm.colorChanged($event)
                _vm.clearErrorMessages("color")
              }
            },
            model: {
              value: _vm.value.color,
              callback: function($$v) {
                _vm.$set(_vm.value, "color", $$v)
              },
              expression: "value.color"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }