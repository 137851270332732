var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCrudTable", {
    ref: "table",
    attrs: {
      tableName: "BenefitsTable",
      headerConfigs: _vm.headerConfigs,
      fetchFunction: _vm.fetchFunction,
      pagination: _vm.pagination
    },
    on: {
      create: _vm.handleCreate,
      details: _vm.handleDetails,
      edit: _vm.handleEdit,
      delete: _vm.handleDelete
    },
    scopedSlots: _vm._u([
      {
        key: "item.icon",
        fn: function(ref) {
          var item = ref.item
          return [
            item.icon_url
              ? _c("v-img", {
                  attrs: {
                    src: item.icon_url,
                    height: "30px",
                    width: "30px",
                    contain: ""
                  }
                })
              : _c("v-icon", { staticClass: "secondary_font--text" }, [
                  _vm._v("fas fa-fw fa-image")
                ])
          ]
        }
      },
      {
        key: "item.color",
        fn: function(ref) {
          var item = ref.item
          return [_c("ColorBox", { attrs: { color: item.color } })]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }