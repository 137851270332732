<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        :max-width="$vuetify.breakpoint.mdAndUp ? '570px' : '330px'"
        persistent
    >
        <BenefitForm
            ref="benefit_form"
            v-model="benefit"
            :class="loading ? 'd-none' : ''"
            :isFormValid.sync="isFormValid"
            :formType="'CREATE'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            :loading="loading"
        />
        <span :class="!loading ? 'd-none' : ''">{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import BenefitForm from '@/components/forms/BenefitForm'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Benefit from '@/models/Benefit'

export default {
    name: 'BenefitsCreateModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, BenefitForm },
    props: {
        benefit: {
            type: Benefit,
            required: true,
        },
    },
    data: vm => {
        return {
            isFormValid: false,
            title: vm.$t('views.levels.benefits.Create.title'),
            loading: false,
        }
    },
    methods: {
        triggerSubmit() {
            this.$refs.benefit_form.submit()
        },
        async beforeConfirm(benefit) {
            if (!this.isFormValid)
                return false

            this.loading = true

            let hasError
            let response = await benefit.create()
                .catch(async err => hasError = await this.errorHandler(err))

            if (hasError) {
                this.loading = false
                return false
            }
            
            await this.$refs.benefit_form.triggerFileUploads()
                .catch(async err => hasError = await this.errorHandler(err))

            if (hasError) {
                this.loading = false
                return false
            }

            // [FID-941] Quick fix para o carregamento de imagens
            await this.$sleep(1000)

            this.loading = false

            this.resolveValue = this.benefit
            return true
        },
        async errorHandler(err) {
            let errType
            if (err.message == 'Network Error')
                errType = 'network_error'
            else if (err.response.data.type)
                errType = err.response.data.type
            else
                errType = 'error'

            const definedErrors = [
                'network_error'
            ]
            errType = definedErrors.includes(errType) ? errType : 'error'
            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`views.levels.benefits.Create.alerts.${errType}`), 'error', resolve)
            })

            return true
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>